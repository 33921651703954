import * as React from 'react';
import * as background from '../assets/Homepage.jpg';
import * as icon from '../assets/logo_large.png';

import PageHeader, { PageHeaderData } from '../components/PageHeader';
import injectSheet, { CSSProperties } from 'react-jss';

import { Footer } from '../components/Footer';
import { MyTheme } from 'src/types/types';
import RequestDemoForm from '../components/Forms/requestDemo';
import Spacer from '../components/Text/Spacer';

type OwnProps = {
    classes: any;
};

const data: PageHeaderData = {
    background,
    icon,
    title: ''
};

const RequestDemo = (props: OwnProps) => {
    const { classes } = props;
    return (
        <>
            <PageHeader data={data} small />
            <div className={classes.main}>
                <div className={classes.container}>
                    <Spacer />
                    <Spacer />
                    <RequestDemoForm lightMode />
                    <Spacer />
                    <Spacer />
                </div>
                <Footer />
            </div>
        </>
    );
};

const styles = (theme: MyTheme): Record<string, CSSProperties> => ({
    main: {
        marginTop: '30vh',
        overflow: 'hidden',
        position: 'relative',
        zIndex: 2,
        backgroundColor: theme.palette.basic.white
    },
    container: {
        ...theme.content,
        maxWidth: '700px'
    }
});

export default injectSheet(styles)(RequestDemo);
